/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



 */





html {
	-webkit-font-smoothing: antialiased;
}

@font-face {
	font-family: Helvetica;
	src: url(./font/poppins/Poppins-SemiBold.ttf);
}

* {
	font-family: Helvetica;
	font-weight: normal !important;
}

*:focus {
	outline: none;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

body {
	margin: 0 0 0 0 !important;
}

.wrapper {
	width: 100%;
	height: 100%;
	min-height: 600px; 
	background-color: #f6f7fb;
	overflow:scroll;
	position: absolute;
}

/*.backgroundCSS {
	position: absolute;
	width: 100%;
	height: 100%;
	min-width: 300px;
	min-height: 450px;
	background-color: #09223f;
	background: url('./resources/background.jpg') no-repeat;
	background-size: cover;
	overflow: auto;
}*/


/*
.currentModeAuto {
position: absolute;
background: url("./resources/automatic.svg") no-repeat;
background-size: cover;
background-position: center;
width: 6vw;
height: 6vw;
left: 2%;
top: 85%;
}

.currentModePresenter {
position: absolute;
background: url("./resources/presenter.svg") no-repeat;
background-size: cover;
background-position: center;
width: 6vw;
height: 6vw;
left: 2%;
top: 85%;
}

.currentModeTimed {
position: absolute;
background: url("./resources/timer.svg") no-repeat;
background-size: cover;
background-position: center;
width: 6vw;
height: 6vw;
left: 2%;
top: 85%;
}

.currentModeText {
position: absolute;
color: #FFA500;
font-size: .7em;
top: 85%;
left: 15%;
width: 6vw;
}

*/

#titlebar {	
	width: 100%;
	height: 60px;
	background-color: transparent;
}



.footerbar{	
	position: absolute;
	display: flex;
	flex-direction: column;
	width:100%;
	height: 50px;	
	align-items: center;	
	background-color: transparent;
	top:80%;
}




.logo {
	position: absolute;
	width: 250px;
	height: 50px;
	margin-left: -125px;
	left: 50%;
	padding-top: 3%;
}

.logoImage {
	position: absolute;
	width: 40px;
	height: auto;
	padding-left: 15%;
}

#logoText {
	color: #fff;
	font-size: 1.5em;
	padding-left: 35%;
	padding-top: 2%;
	letter-spacing: 5px;
}

.titleMessage {
	position: absolute;
	width: 100%;
	height: 30px;
	top: 35%;
	text-align: center;
	font-size: 1.3em;
	color: #0C375A;
	z-index: 1;
}

.errorMessage {
	position: absolute;
	color: red !important;
	font-size: 1.2em;
	font-weight: normal;
	width: 100%;
	height: 5%;
	top: 70%;
	text-align: center;
	display: none
}

.questionWindow {
	display: flex;
	/* Safari */
	flex-basis: 100%;
	height: 80%;
	flex: 1;
	align-items: center;
	flex-direction: column;
	margin-top: 3%;
}

.questionNoText {
	color: #008C96 !important;
	font-size: 1.5em;
	font-weight: normal;
	flex: auto;
	text-align: center;
	margin: 2px 0px 0px 0px;
}

.questionText {
	/* color: #00B691!important; */
	color: #8BDC7D!important;
	font-size: 1.7em;
	font-weight: normal;
	flex: 1;
	width: 85%;
	text-align: center;
	margin: 10px 0px 0px 0px;
}

.questionImage {
	flex: auto;
	width: 100%;
	height: auto;
	max-width: 100px;
	min-width: 100px;
	min-height: 100px;
}

.optionTextSpan {
	flex: auto;
	width: 100%;
	margin-top: 15px;
	display: flex;
	align-items: flex-start;
	transition: width .25s linear;
	-o-transition: width .25s linear;
	-moz-transition: width .25s linear;
	-webkit-transition: width .25s linear;
}

.optionText {
	flex: 1;
	width: 90%;
	max-width: 600px;
	min-height: 60px;
	padding: 2% 1% 1% 1%;
	margin: 1% 2% 2% 2%;
	color: #0C375A !important;
	font-size: 1.3em;
	font-weight: normal;
	border: 2px solid #0C375A;
	background-color: transparent;
	border-radius: 10px;
	text-align: center;
}

.optionText:active {
	flex: 1;
	width: 90%;
	max-width: 600px;
	padding: 2% 1% 1% 1%;
	margin: 1% 2% 2% 2%;
	color: white !important;
	font-size: 1.3em;
	font-weight: normal;
	border: 2px solid #F9F871;
	background-color: #F9F871 !important;
	border-radius: 10px;
	text-align: center;
}

.optionText:hover {
	flex: 1;
	width: 90%;
	max-width: 600px;
	padding: 2% 1% 1% 1%;
	margin: 1% 2% 2% 2%;
	color: #008C96 !important;
	font-size: 1.3em;
	font-weight: normal;
	border: 2px solid #008C96;
	background-color: #f6f7fb !important;
	border-radius: 10px;
	text-align: center;
}


.optionTextNPS {
	width: 40px!important;
	height:40px!important;
	padding: 1% 1% 1% 1%;
	margin: 1% 1% 1% 1%;
	color: #0C375A !important;
	font-size: 1.8em;
	font-weight: normal;
	border: 2px solid #0C375A;
	background-color: transparent;
	border-radius: 10%;
	text-align: center;
}

.optionTextNPS:active {
	width: 40px!important;
	height:40px!important;
	padding: 1% 1% 1% 1%;
	margin: 1% 1% 1% 1%;
	color: white !important;
	font-size: 1.8em;
	font-weight: normal;
	border: 2px solid #F9F871;
	background-color: #F9F871 !important;
	border-radius: 10%;
	text-align: center;
}

.optionTextNPS:hover {
	width: 40px!important;
	height:40px!important;
	padding: 1% 1% 1% 1%;
	margin: 1% 1% 1% 1%;
	color: #008C96 !important;
	font-size: 1.8em;
	font-weight: normal;
	border: 2px solid #008C96;
	background-color: #f6f7fb !important;
	border-radius: 10%;
	text-align: center;
}

.optionsDIVNPS{
	flex: 1;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 50% !important;
	margin: 0% 20% 0% 20%;
}



.optionImage {
	flex: 1;
	width: 100%;
	height: auto;
	max-width: 100px;
	max-height: 100px;
	min-width: 100px;
	min-height: 100px;
}

.imageLabel {
	flex: 1;
	margin-top: 10px;
	padding-top: 5px;
	line-height: 15px;
	display: inline-block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	line-height: 15px;
	max-width: 150px;
	max-height: 100px;
	font-size: 1.2em;
}

.optionsDIV {
	flex: 1;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 80%;
	min-height: 150px;
}

.optionsCommentDIV {
	flex: 1;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	width: 80%;
	min-height: 150px;
}

.imageOptionsDIV {
	flex: 1;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	width: 80%;
}

.imageOptions {
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	color: white !important;
	font-size: 0.8em;
	font-weight: normal;
	border: 2px solid transparent;
	background-color: transparent;
	border-radius: 10px;
	margin: 1% 1% 1% 1%;
	max-width: 150px !important;
	min-width: 150px !important;
	min-height: 150px !important;
}

.imageOptionNoText {
	flex: 1;
	width: 90%;
	max-width: 600px;
	min-width: 200px;
	padding: 1% 1% 1% 1%;
	margin: 1% 2% 2% 2%;
	color: white !important;
	font-size: 1.3em;
	font-weight: normal;
	border: 2px solid transparent;
	background-color: transparent;
	border-radius: 10px;
}

.submitBtn {
	flex: 1;
	padding: 1% 1% 1% 1%;
	margin: 5% 2% 2% 2%;
	background-color: #00B691 !important;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid transparent;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.submitBtn:hover {
	flex: 1;
	padding: 1% 1% 1% 1%;
	margin: 5% 2% 2% 2%;
	background-color: #008C96  !important;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid #00B691;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.submitBtn:active {
	flex: 1;
	padding: 1% 1% 1% 1%;
	margin: 5% 2% 2% 2%;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid #F9F871;
	background-color: #F9F871 !important;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.submitBtnComment {
	background-color:  #00B691 !important;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid transparent;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.submitBtnComment:hover {
	background-color: #008C96 !important;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid #00B691;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.submitBtnComment:active {
	background-color: #F9F871 !important;
	color: white !important;
	font-size: 1.3em;
	text-align: center;
	border: 2px solid #F9F871;
	width: 250px;
	height: 50px;
	border-radius: 25px;
}

.EndingPage_TitleText {
	position: absolute;
	width: 100%;
	height: 30px;
	top: 50%;
	text-align: center;
	font-size: 1.4em;
	color: #0C375A;
}

.disconnectMessage_Text{
	position: absolute;
	width: 100%;
	height: 30px;
	top: 50%;
	text-align: center;
	font-size: 1.4em;
	color: #0C375A;
}


.inputTextBox {
	width: 250px;
	height: 100px;
	top: 35%;
	left: 50%;
	margin-left: -125px;
	position: absolute;
}

#text1 {
	position: relative;
	top: 40%;
	left: 1%;
}

#text2 {
	position: absolute;
	top: 40%;
	left: 25%;
}

#text3 {
	position: absolute;
	top: 40%;
	left: 49%;
}

#text4 {
	position: absolute;
	top: 40%;
	left: 73%;
}

.pollId {
	font-size: 2.5em;
	text-align: center;
	background-color: transparent;
	color: #0C375A ;
	border: 1px solid #00B691;
 	 width:50px !important;
	 height:50px!important;
    max-width: 50px !important;
	max-height: 50px !important;
	padding: 0; 

}

.button {
	position: absolute;
	top: 60%;
	left: 50%;
	border-color: transparent;
	background-color: #00B691 !important;
	color: white;
	font-size: 1em;
	border-radius: 5px;
	text-align: center;
	width: 120px;
	height: 40px;
	margin-left: -60px;
}

.button:hover {
	position: absolute;
	top: 60%;
	left: 50%;
	border: 2px solid #00B691;
	background-color: #008C96 !important;
	color: white;
	font-size: 1em;
	border-radius: 5px;
	text-align: center;
	width: 120px;
	height: 40px;
	margin-left: -60px;
}
.button:focus {
	position: absolute;
	top: 60%;
	left: 50%;
	border: 2px solid #00B691;
	background-color: #008C96 !important;
	color: white;
	font-size: 1em;
	border-radius: 5px;
	text-align: center;
	width: 120px;
	height: 40px;
	margin-left: -60px;
}

.button:active {
	position: absolute;
	top: 60%;
	left: 50%;
	border: 2px solid #F9F871;
	background-color: #F9F871 !important;
	color: white;
	font-size: 1em;
	border-radius: 5px;
	text-align: center;
	width: 120px;
	height: 40px;
	margin-left: -60px;
}

#commentText {
	flex: 5;
	width: 85%;
	height: 300px;
	max-width:500px;
	max-height: 300px;
	margin-bottom: 2%;
	padding: 1% 1% 1% 1%;
	resize: none;
	border: 1.4px solid #00B691;
	background-color: transparent;
	color: #0C375A;
	font-size: 1em;
}

.commentBlankMessage {
	flex: 1;
	color: red !important;
	font-size: 1.2em;
	font-weight: normal;
	width: 100%;
	height: 10%;
	text-align: center;
	margin-top: 20px;
	display: none;
}

.valueItems {
	display: inline-block;
	vertical-align: middle;
}












/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) {
  
.questionImage {
    max-width: 250px;
    min-height: 250px;
}

.questionText {
    font-size: 1.5em;
}

.imageLabel {
   font-size: 1.5em;
   max-width: 250px;
}

.imageOptions {
	 max-width: 250px !important;
}

}

